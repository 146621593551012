<template>
  <div class="column is-12 has-text-centered">
    <div class="box">
      <div class="columns is-multiline is-centered">
        <div class="column is-8">
          <img class="imagen_error" src="@/assets/image/warnig-error.png" alt="Sin información">
          <h1 class="title has-text-centered">
            Aún No Hay Información <br>
            Para Mostrar
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoInformation'
}
</script>

<style lang="scss" scoped>
  h1 {
    font-size: 18px;
    line-height: 1.25;
    font-weight: normal;
    text-align: center;
    color: #b5b5c3;
  }
  .imagen_error {
    max-width: 100px;
    margin-bottom: 2.5rem;
  }
  .box {
    margin: 1.5rem;
    min-height: calc(100vh - 260px);
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #e1e5eb;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
</style>
